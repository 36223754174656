import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import '../components/styles/seance.css';
import Layout from '../components/layout';
import HeaderRandom from '../components/headerRandom';
import Seo from '../components/seo';

const Acces = () => (
  <Layout>
    <Seo />
    <HeaderRandom />
    <div id='title'>
      <h1>Mes certifications</h1>
    </div>

    <div className='division'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <br /><br />
            <StaticImage src='../img/certificateintegrative.png' className='center block' placeholder='blurred' alt='certificateintegrative' width={360} height={252} />
          </div>
          <div className='col-md-4'>
            <br /><br />
            <StaticImage src='../img/certificateflash.png' className='center block' placeholder='blurred' alt='certificateflash' width={360} height={252} />
          </div>
          <div className='col-md-4'>
            <br /><br />
            <StaticImage src='../img/certicatetabac.png' className='center block' placeholder='blurred' alt='certificatetabac' width={360} height={252} />
          </div>
          <div className='col-md-4'>
            <br /><br />
            <StaticImage src='../img/certicatefranceh.png' className='center block' placeholder='blurred' alt='certificatefranceh' width={360} height={252} />
          </div>
          <div className='col-md-4'>
            <br /><br />
            <StaticImage src='../img/cert1.png' className='center block' placeholder='blurred' alt='certificatefranceh' width={360} height={252} />
          </div>
          <div className='col-md-4'>
            <br /><br />
            <StaticImage src='../img/cert3.png' className='center block' placeholder='blurred' alt='certificatefranceh' width={360} height={252} />
          </div>
          <div className='col-md-4'>
            <br /><br />
            <StaticImage src='../img/cert2.png' className='center block' placeholder='blurred' alt='certificatefranceh' width={360} height={498} />
          </div>
          <div className='col-md-4'>
            <br /><br />
            <StaticImage src='../img/certificatesuivi.png' className='center block' placeholder='blurred' alt='certificatesuivi' width={360} height={498} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Acces;
